import React from "react";
import Header from "../components/common/header";
import Footer from "../components/common/footer";
import { Container } from "react-bootstrap";

class NotFoundPage extends React.Component {
    render(){
        return (
            <div>
                <Header pageName={"Not Found - 404"}/>
                <Container>
                    <p className={"pageNotFound"}>The page you are looking for does not exist, please consult the sitemap in the footer.</p>
                </Container>
                <Footer />
            </div>
        )
    }
}

export default NotFoundPage
